:root {
    
    /* Primary Colors */
    --ion-color-primary: #FF5CA4;   /* Pink */
    --ion-color-primary-rgb: 255, 92, 164;
  
    --ion-color-secondary: #8C52FF; /* Purple */
    --ion-color-secondary-rgb: 140, 82, 255;
  
    /* Accent Colors */
    --ion-color-success: #39FF14;   /* Neon Green */
    --ion-color-warning: #FFD700;   /* Yellow */
  
    /* Text and Background Colors */
    --ion-background-color: linear-gradient(135deg, #3A1C71, #D76D77, #FFAF7B);
    
    --ion-text-color: #FFFFFF; /* White text for better readability */
    --ion-text-color-rgb: 255, 255, 255;
    
  }
  